import {
	createRouter,
	createWebHistory,
	RouteLocationNormalized,
	RouteRecordRaw,
} from 'vue-router'
import { useAuthStore } from '@/composable/StoreUtils/useAuthStore'
import { useDesignSettingsStore } from '@/composable/StoreUtils/useDesignSettingsStore'
import api from '@/config/axios'
import MainLayout from '@/layouts/MainLayout.vue'
import QuickCanvasRoutes from '@/router/Modules/QuickCanvasRoutes'
import { settings } from '@/settings/DynamicSettings'
import { SingleSetupStore } from '@/store/modules/singleSetup'
import { rootStore, useSelector } from '@/store/rootStore'

type RouteWithMeta = RouteRecordRaw & {
	meta?: {
		requiresAuth?: boolean
		checkForCompanyRestrictions?: boolean
		requiresCompanyInfo?: boolean
	}
}

let hasLoadedSingleSetupData = false

const routes: Array<RouteWithMeta> = [
	{
		path: '/',
		component: MainLayout,
		redirect: { name: 'editor' },
		children: [...QuickCanvasRoutes],
	},
	{
		name: '404',
		path: '/404',
		component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

function setIsTemplateFromSettings(value: boolean) {
	const { settings, setDesignSettings } = useDesignSettingsStore()
	setDesignSettings({ ...settings.value, isTemplate: value })
}

const navigationGuards = {
	requiresAuth: async (to: RouteLocationNormalized) => {
		if (!to.matched.some((record) => record.meta.requiresAuth)) return

		const { isAuthenticated, isAuthorized, tokenExchange } = useAuthStore()
		const { query, params } = to
		const sessionId = query.sessionId?.toString()
		const supplierId = query.supplier_id?.toString()
		const distributorId = query.distributor_id?.toString()
		const temporalToken = query.t?.toString()
		if (!isAuthenticated.value) {
			if (sessionId && supplierId) {
				await tokenExchange(sessionId, supplierId, distributorId)
			} else {
				await isAuthorized(temporalToken)
			}
		}
		const type = query.type?.toString()

		if (!isAuthenticated.value) {
			window.location.href = import.meta.env.VITE_LOGIN_URL as string
			return false
		}

		const SINGLE_SETUP_API = new URL(
			settings.SINGLE_SETUP_URL(params.id?.toString())
		)
		if (distributorId) {
			SINGLE_SETUP_API.searchParams.set('distributor_id', distributorId)
		}

		if (!hasLoadedSingleSetupData) {
			const singleSetupResponse = await api.get<SingleSetupStore['data']>(
				SINGLE_SETUP_API.toString()
			)
			rootStore.commit({
				type: 'singleSetup/setSingleSetupData',
				payload: singleSetupResponse.data,
			})
			hasLoadedSingleSetupData = true
		}

		if (temporalToken) {
			if (type === 'template') {
				setIsTemplateFromSettings(true)
			}
			const newQueryParams = query
			delete newQueryParams.t
			delete newQueryParams.type
			return { query: newQueryParams, params }
		} else if (sessionId) {
			const newQueryParams = query
			delete newQueryParams.sessionId
			delete newQueryParams.supplier_id
			delete newQueryParams.client_id
			delete newQueryParams.distributor_id
			return { query: newQueryParams, params }
		}
	},
	checkForCompanyRestrictions: async (to: RouteLocationNormalized) => {
		if (!to.matched.some((record) => record.meta.checkForCompanyRestrictions))
			return

		const singleSetupData = useSelector((state) => state.singleSetup.data)
		const companySettingsResponse = singleSetupData.value?.settings

		rootStore.commit({
			type: 'project/setCompanySettings',
			payload: companySettingsResponse,
		})

		if (!companySettingsResponse?.settings?.restrictions) return

		const restrictionKeyList: string[] = [
			'smart-layouts.edit',
			'smart-layouts.create',
			'smart-layouts-templates.edit',
			'smart-layouts-templates.create',
		]

		const hasRestriction = restrictionKeyList.some(
			(restrictionKey) =>
				restrictionKey in (companySettingsResponse?.settings?.restrictions ?? {})
		)
		if (hasRestriction) return '/404'
	},
	requiresCompanyInfo: async (to: RouteLocationNormalized) => {
		if (!to.matched.some((record) => record.meta.requiresCompanyInfo)) return

		const singleSetupData = useSelector((state) => state.singleSetup.data)
		const companyResponse = singleSetupData.value?.company_info

		rootStore.commit({
			type: 'auth/setCompany',
			payload: companyResponse,
		})
		rootStore.commit({
			type: 'auth/setIsTeamMember',
			payload: Boolean(companyResponse?.role_name),
		})
		rootStore.commit({
			type: 'auth/setRole',
			payload: companyResponse?.role_name,
		})
	},
}

router.beforeEach(async (to, from, next) => {
	document.title = import.meta.env.VITE_TITLE
	const navigationGuardList = Object.values(navigationGuards)

	for (const navigationGuard of navigationGuardList) {
		const routeGuardResponse = await navigationGuard(to)
		if (routeGuardResponse) return next(routeGuardResponse)
	}

	next()
})

export default router
