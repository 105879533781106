import * as Sentry from '@sentry/vue'
import { debounce, throttle } from '@zoomcatalog/shared'
import compressor from 'pako'
import { useNotification } from '@/composable/useNotification'
import { rootStore } from '@/store/rootStore'
import { queueMutator } from './queueModifiers'
import {
	ADD_PAGE,
	AutoSaveOptions,
	BaseCommand,
	CLONE_PAGE,
	Command,
	DELETE_PAGE,
	isMultiEventsCommand,
	KeepAliveCommand,
	SocketMessage,
	UPDATE_PAGE,
	UPDATE_PAGES_INDEXES,
	WebSocketPayload,
} from './types'

export class AutoSave {
	#socketUrl: string
	#socket!: WebSocket
	#loggerEnabled: boolean

	#keepAliveIntervalId?: number
	#canSendMessages = false
	isSending = false
	isServerLocked = false
	#canEnqueueMessages = false
	#canSendKeepAlive = false
	#messageId = 0

	#lowestSizeToCompress = 32_768
	#retryUntilMs = 500
	#debounceQueueMs = 2_000
	lastExpectedCommand: Command | null = null
	lastCommandSent: Command | null = null
	outgoingActions: Array<string> = []
	commandsHistory: Array<Command> = []
	socketErrors = ['locked', 'not found', 'Invalid action', 'Duplicate']
	paginationCommands: Command['action'][] = [
		ADD_PAGE,
		DELETE_PAGE,
		CLONE_PAGE,
		UPDATE_PAGE,
		UPDATE_PAGES_INDEXES,
	]
	ignoredWSCodes = [1000, 1001]

	constructor(options: AutoSaveOptions) {
		this.#loggerEnabled = options.enableLogger ?? true

		this.#socketUrl = options.socketUrl
		this.makeSocketConnection()
	}

	getBaseCommand(): BaseCommand {
		this.#messageId += 1
		const userToken = localStorage.getItem('userToken')

		return {
			endpoint: 'send_changes',
			resource: 'flyer',
			id: this.#messageId,
			token: userToken || '',
		}
	}

	getSocket() {
		return this.#socket
	}

	hasConnection() {
		return this.#socket.readyState === this.#socket.OPEN && navigator.onLine
	}

	createKeepAliveInterval() {
		this.#keepAliveIntervalId = window.setInterval(() => {
			if (!this.#canSendKeepAlive || !this.hasConnection()) return

			this.keepAlive()
		}, 20_000)
	}

	setCanSendMessages(value: boolean) {
		this.#canSendMessages = value
	}

	setCanEnqueueMessages(value: boolean) {
		this.#canEnqueueMessages = value
	}

	setIsServerLocked(value: boolean) {
		this.isServerLocked = value
	}

	makeSocketConnection() {
		if (this.#socket && this.#socket.readyState !== this.#socket.CLOSED) {
			return
		}

		this.makeNewConnection()
	}

	makeNewConnection() {
		this.#socket = new WebSocket(this.#socketUrl)
		this.setupEvents()
	}

	isSocketSuccess(message: SocketMessage) {
		return (
			(typeof message === 'object' && message.code === 200) ||
			(Array.isArray(message) && message.every((msg) => msg.code === 200))
		)
	}

	setupEvents() {
		this.#socket.onopen = () => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log('AutoSave socket connected')
			}

			this.setCanSendKeepAlive(true)
		}

		this.#socket.onclose = ($event) => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log('AutoSave socket disconnected')
			}

			if (!this.ignoredWSCodes.includes($event.code)) {
				if (navigator.onLine) {
					Sentry.captureEvent({
						message: `${$event.code}: "${$event.reason}" - Websocket`,
						level: Sentry.Severity.Error,
					})
				} else {
					Sentry.captureEvent({
						message: `${$event.code}: "No internet connection" - Websocket`,
						level: Sentry.Severity.Warning,
					})
				}
			}

			if (navigator.onLine) this.makeNewConnection()
		}

		this.#socket.onmessage = (event: { data: string }) => {
			const { data } = event
			const message = JSON.parse(data) as SocketMessage
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.log(`AutoSave socket message: `, event)
			}

			const lastAction = this.outgoingActions.shift()
			if (lastAction === 'keep-alive') return
			if (
				(this.lastExpectedCommand?.action === this.lastCommandSent?.action &&
					!this.isSending) ||
				(!this.lastExpectedCommand && !this.isSending)
			) {
				rootStore.commit({ type: 'autoSave/setIsSending', payload: false })
			}

			if (!this.isSocketSuccess(message)) {
				this.setIsServerLocked(true)
				this.setCanSendMessages(false)
				Sentry.captureEvent({
					message: `${data.toString()} - Websocket`,
					level: Sentry.Severity.Error,
				})
			} else {
				this.setIsServerLocked(false)
				this.setCanSendMessages(true)
				this.setCanSendKeepAlive(true)
			}
		}

		this.#socket.onerror = (event) => {
			if (this.#loggerEnabled) {
				// eslint-disable-next-line no-console
				console.error(`AutoSave socket error: `, event)
			}

			Sentry.captureEvent({
				message: `There was an error with the websocket.`,
				level: Sentry.Severity.Error,
			})
			if (rootStore.state.autoSave.numberOfReconnects > 0) return
			this.showNotification()
		}
	}

	showNotification = throttle(() => {
		const { enqueueNotification } = useNotification()
		enqueueNotification({
			message:
				"We were not able to save this design. We'll keep retrying. Refreshing might lose your work.",
			color: 'danger',
		})
	}, 10000)

	debounceQueue = debounce(async (shouldMutateQueue: boolean) => {
		await this.sendEvents(shouldMutateQueue)
	}, this.#debounceQueueMs)

	enqueue<T extends Command>(
		command: Omit<T, 'resource' | 'endpoint' | 'id' | 'token'>,
		options = { shouldMutate: true }
	) {
		if (!this.#canEnqueueMessages) return
		this.setCanSendKeepAlive(false)
		rootStore.commit({ type: 'autoSave/setIsSending', payload: true })
		const newCommand = {
			...this.getBaseCommand(),
			...command,
		} as unknown as Command

		rootStore.commit({
			type: 'autoSave/enqueueCommand',
			payload: newCommand,
		})
		this.commandsHistory.push(newCommand)
		this.debounceQueue(options.shouldMutate)
	}

	setCanSendKeepAlive(flag: boolean) {
		this.#canSendKeepAlive = flag
		clearInterval(this.#keepAliveIntervalId)
		if (flag) this.createKeepAliveInterval()
	}

	async sendEvents(shouldMutateQueue: boolean) {
		await this.retryUntil(() => !this.isSending)

		const queueCommands = rootStore.state.autoSave.queue
		rootStore.commit({ type: 'autoSave/cleanQueue' })

		const mutatedQueue = shouldMutateQueue
			? queueMutator(queueCommands)
			: queueCommands

		this.lastExpectedCommand = mutatedQueue[mutatedQueue.length - 1]

		for (const command of mutatedQueue) {
			this.setCanSendKeepAlive(false)
			rootStore.commit({ type: 'autoSave/setIsSending', payload: true })
			this.isSending = true
			await this.retryUntil(() => {
				if (this.isServerLocked && this.lastCommandSent && this.hasConnection()) {
					this.sendCommand(
						this.compressCommand(this.lastCommandSent, this.#lowestSizeToCompress)
					)
				}

				return this.#canSendMessages && !this.isServerLocked && this.hasConnection()
			})

			this.sendCommand(this.compressCommand(command, this.#lowestSizeToCompress))
			this.lastCommandSent = command
			this.outgoingActions.push(command.action)
			this.setCanSendMessages(false)
		}
		this.isSending = false
	}

	retryUntil(condition: () => boolean) {
		const poll = (resolve: (value: boolean) => void) => {
			if (condition()) {
				resolve(true)
			} else {
				setTimeout(() => poll(resolve), this.#retryUntilMs)
			}
		}

		return new Promise(poll)
	}

	keepAlive() {
		const keepAliveCommand: KeepAliveCommand = {
			...this.getBaseCommand(),
			action: 'keep-alive',
			data: {},
		}
		this.sendCommand(keepAliveCommand)
		this.outgoingActions.push(keepAliveCommand.action)
	}

	sendCommand(command: WebSocketPayload) {
		this.#socket.send(JSON.stringify(command))
	}

	compressCommand(command: Command, lowestSize: number): WebSocketPayload {
		if (
			JSON.stringify(command).length < lowestSize ||
			isMultiEventsCommand(command)
		)
			return command

		const commandString = JSON.stringify(command.data)

		const unicodeList = new TextEncoder().encode(commandString)

		const gzippedList = compressor.gzip(unicodeList)

		const gzippedString = Array.from(gzippedList)
			.map((unicode) => String.fromCharCode(unicode))
			.join('')

		const b64Data = btoa(gzippedString)

		return { ...command, data: b64Data }
	}
}
