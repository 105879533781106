import { Module } from 'vuex'
import type { Event, EventPayload } from '@/ts-definitions'
import type { Command } from '../plugins/autoSave/types'
import { RootStore } from '../rootStore'

export type AutoSaveStore = {
	numberOfChanges: number
	numberOfReconnects: number
	isSending: boolean
	queue: Array<Command>
}

type AutoSaveNamespace = 'autoSave'

type IncreaseChanges = Event<`${AutoSaveNamespace}/increaseChanges`>
type EnqueueCommand = EventPayload<
	`${AutoSaveNamespace}/enqueueCommand`,
	Command
>
type SetIsSending = EventPayload<`${AutoSaveNamespace}/setIsSending`, boolean>
type OverrideFirstCommand = EventPayload<
	`${AutoSaveNamespace}/overrideFirstCommand`,
	Command
>
type SendMultiCommands = EventPayload<
	`${AutoSaveNamespace}/sendMultiCommands`,
	Command[]
>
type DeleteFirstCommand = Event<`${AutoSaveNamespace}/deleteFirstCommand`>
type CleanQueue = Event<`${AutoSaveNamespace}/cleanQueue`>
type Reconnect = Event<`${AutoSaveNamespace}/reconnect`>

export type AutoSaveMutations =
	| IncreaseChanges
	| EnqueueCommand
	| CleanQueue
	| DeleteFirstCommand
	| OverrideFirstCommand
	| Reconnect
	| SendMultiCommands
	| SetIsSending

export const autoSaveStore: Module<AutoSaveStore, RootStore> = {
	namespaced: true,
	state: () => ({
		numberOfChanges: 0,
		numberOfReconnects: 0,
		isSending: false,
		queue: [],
	}),
	actions: {
		queueShift(context) {
			const firstCommand = context.state.queue[0]
			context.commit('deleteFirstCommand')
			return firstCommand
		},
	},
	mutations: {
		increaseChanges(state) {
			state.numberOfChanges++
		},
		enqueueCommand(state, { payload: command }: EnqueueCommand) {
			state.queue.push(command)
		},
		reconnect(state) {
			state.numberOfReconnects++
		},
		deleteFirstCommand(state) {
			state.queue.shift()
		},
		cleanQueue(state) {
			state.queue = []
		},
		setIsSending(state, { payload }: SetIsSending) {
			state.isSending = payload
		},
		sendMultiCommands() {
			// SendMultiCommands
		},
	},
}
