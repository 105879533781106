import { Page } from '@/store/interfaces'

export const dimensions = {
	width: 816,
	height: 1056,
}

export const emptyPageTemplate: Omit<Page, 'project_id' | 'page_id' | 'index'> =
	{
		entity_type: 'page',
		layout: {
			layout_id: '45a47a3f58d24a0a8861420a4a5fade4',
			logos_slots_quantity: 1,
			name: 'Basic four products',
			products_slots_quantity: 4,
		},
		layout_id: '45a47a3f58d24a0a8861420a4a5fade4',
		logos: {},
		products: {},
		status: 'active',
		created_at: '',
		updated_at: '',
		settings: {
			feature: {
				spreadPage: null,
			},
		},
		cover_image: '',
		title: '',
	}
