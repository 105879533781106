import { rootStore } from '@/store/rootStore'
import {
	AddProductsCommand,
	Command,
	isAddPageCommand,
	isAddProductsCommand,
	isDeletePageCommand,
	isMultiEventsCommand,
	isUpdatePageCommand,
	isUpdatePageMediaCommand,
	isUpdatePagesIndexesCommand,
	MultiEventsCommand,
	UpdatePageCommand,
	UpdatePageMediaCommand,
	UpdatePagesIndexesCommand,
	WebSocketPayload,
} from './types'

// If update_pages_indexes exists multiple times only use last instance
function updatePagesIndexesMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasUpdatePagesIndexes = newQueue.some(isUpdatePagesIndexesCommand)
	if (!hasUpdatePagesIndexes) return newQueue

	const updatePagesIndexesCommands = newQueue.filter(isUpdatePagesIndexesCommand)
	const affectedPageNumbersRange = updatePagesIndexesCommands.reduce(
		(group, command) => {
			const min = Object.values(command.data.pages).reduce(
				(prevPage, pageNumber) => (prevPage < pageNumber ? prevPage : pageNumber),
				Infinity
			)
			const max = Object.values(command.data.pages).reduce(
				(prevPage, pageNumber) => (prevPage > pageNumber ? prevPage : pageNumber),
				-Infinity
			)

			return { min: Math.min(group.min, min), max: Math.max(group.max, max) }
		},
		{ min: Infinity, max: -Infinity }
	)
	const lastUpdatePagesIndexesCommand = updatePagesIndexesCommands.pop()

	if (!isUpdatePagesIndexesCommand(lastUpdatePagesIndexesCommand))
		return newQueue

	const queueWithoutUpdatePagesIndexesCommands = newQueue.filter(
		(command) => !isUpdatePagesIndexesCommand(command)
	)
	const lastUpdatePagesIndexesCommandUpdated: UpdatePagesIndexesCommand = {
		...lastUpdatePagesIndexesCommand,
		data: {
			...lastUpdatePagesIndexesCommand.data,
			pages: Object.values(rootStore.state.page.basicPages)
				.filter((page) => {
					const hasDeletePageCommand = newQueue.some(isDeletePageCommand)
					if (hasDeletePageCommand) {
						return page.index >= affectedPageNumbersRange.min
					}

					return (
						page.index >= affectedPageNumbersRange.min &&
						page.index <= affectedPageNumbersRange.max
					)
				})
				.reduce((obj, page) => ({ ...obj, [page.page_id]: page.index }), {}),
		},
	}

	if (
		Object.values(lastUpdatePagesIndexesCommandUpdated.data.pages).length > 0
	) {
		newQueue = [
			...queueWithoutUpdatePagesIndexesCommands,
			lastUpdatePagesIndexesCommandUpdated,
		]
	} else {
		newQueue = queueWithoutUpdatePagesIndexesCommands
	}

	return newQueue
}

// If update_pages_indexes is too long split in chunks
function updatePagesIndexesChunkerMutator(commandList: Command[]) {
	let newQueue = commandList
	const chunkSize = 50

	const hasUpdatePagesIndexes = newQueue.some(isUpdatePagesIndexesCommand)
	if (!hasUpdatePagesIndexes) return newQueue

	const updatePagesIndexesCommand = newQueue.find(isUpdatePagesIndexesCommand)
	if (
		!updatePagesIndexesCommand ||
		Object.values(updatePagesIndexesCommand.data.pages).length <= chunkSize
	)
		return newQueue

	const chunkedCommandPages = Object.keys(
		updatePagesIndexesCommand.data.pages
	).reduce<Record<string, number>[]>((group, pageId, index) => {
		const chunkIndex = Math.floor(index / chunkSize)
		if (!group[chunkIndex]) {
			group[chunkIndex] = {}
		}
		group[chunkIndex] = {
			...group[chunkIndex],
			[pageId]: updatePagesIndexesCommand.data.pages[pageId],
		}
		return group
	}, [])

	const updatePagesIndexesCommands: UpdatePagesIndexesCommand[] =
		chunkedCommandPages.map((pagesChunk) => {
			return {
				...updatePagesIndexesCommand,
				data: {
					...updatePagesIndexesCommand.data,
					pages: pagesChunk,
				},
			}
		})

	const queueWithoutUpdatePagesIndexesCommands = newQueue.filter(
		(command) => !isUpdatePagesIndexesCommand(command)
	)

	newQueue = [
		...queueWithoutUpdatePagesIndexesCommands,
		...updatePagesIndexesCommands,
	]

	return newQueue
}

// Filter last instance of update_page perPage
function updatePageMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasUpdatePage = newQueue.some(isUpdatePageCommand)
	if (!hasUpdatePage) return newQueue

	const uniqueUpdatePageCommands = newQueue
		.filter(isUpdatePageCommand)
		.reduce<Record<string, UpdatePageCommand>>((group, command) => {
			if (!isUpdatePageCommand(command)) return group

			group[command.data.page_id] = command
			return group
		}, {})

	newQueue = newQueue.filter((command) => !isUpdatePageCommand(command))

	return [...newQueue, ...Object.values(uniqueUpdatePageCommands)]
}

// Filters unique actions of add_products or remove_product perPage and perSlot
function addProductsMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasAddProducts = newQueue.some(isAddProductsCommand)
	if (!hasAddProducts) return newQueue

	const uniqueAddProductsCommandsMap = newQueue
		.filter(isAddProductsCommand)
		.reduce<Record<string, Record<number, AddProductsCommand>>>(
			(group, command) => {
				if (!isAddProductsCommand(command)) return group
				if (!group[command.data.page_id]) group[command.data.page_id] = {}

				command.data.products.forEach((product) => {
					group[command.data.page_id][product.slot] = command
				})

				return group
			},
			{}
		)

	const mergeAddCommands = Object.keys(uniqueAddProductsCommandsMap).reduce<
		Record<string, AddProductsCommand>
	>((group, pageId) => {
		const addProductsCommand = Object.values(
			uniqueAddProductsCommandsMap[pageId]
		).reduce<AddProductsCommand>((command, slotCommand) => {
			if (!command?.data?.products) {
				command = slotCommand
				return command
			}
			command.data.products = [
				...command.data.products,
				...slotCommand.data.products,
			]
			return command
		}, {} as AddProductsCommand)

		group[pageId] = addProductsCommand

		return group
	}, {})

	newQueue = newQueue.filter((command) => !isAddProductsCommand(command))

	return [...newQueue, ...Object.values(mergeAddCommands)]
}

// Groups spread pages into a single command (multi_events)
function addSpreadPageMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasAddPage = newQueue.some(isAddPageCommand)
	if (!hasAddPage) return newQueue

	const spreadPagesList = newQueue.reduce<
		Array<{ left: Command; right: Command }>
	>((group, command, index) => {
		if (
			!isAddPageCommand(command) ||
			command.data.settings?.feature?.spreadPage !== 'left'
		)
			return group

		group.push({
			left: newQueue[index],
			right: newQueue[index + 1],
		})

		return group
	}, [])

	const spreadPagesMultiEvents = spreadPagesList.map((spreadPage) => {
		const userToken = localStorage.getItem('userToken')

		const multiEvents: MultiEventsCommand = {
			id: spreadPage.left.id,
			action: 'multi_events',
			endpoint: 'send_changes',
			commands: [spreadPage.left, spreadPage.right],
			token: userToken || '',
		}

		return multiEvents
	})

	newQueue = newQueue.filter(
		(command) =>
			!isAddPageCommand(command) || !command.data.settings?.feature?.spreadPage
	)

	return [...newQueue, ...spreadPagesMultiEvents]
}

// Sort commands by id
function sortById(commandList: Command[]) {
	const newQueue = commandList
	newQueue.sort((a, b) => a.id - b.id)

	return newQueue
}

// Merge addPage and updatePage if is same page
function mergeAddPageMutator(commandList: Command[]) {
	const newQueue: Command[] = []
	let ignoreCommandId = -1

	commandList.forEach((command, index) => {
		if (command.id === ignoreCommandId) return
		if (!isAddPageCommand(command)) return newQueue.push(command)
		const nextCommand = commandList[index + 1]
		if (!isUpdatePageCommand(nextCommand)) return newQueue.push(command)

		const addPageCommand = command
		const updatePageCommand = nextCommand

		const isSamePage =
			addPageCommand.data.page_id === updatePageCommand.data.page_id
		if (!isSamePage) return newQueue.push(command)

		ignoreCommandId = updatePageCommand.id
		const mergedPageSettings = {
			...addPageCommand.data.settings,
			...updatePageCommand.data.settings,
		}
		return newQueue.push({
			...addPageCommand,
			data: {
				...addPageCommand.data,
				...updatePageCommand.data,
				settings: mergedPageSettings,
			},
		})
	})

	return newQueue
}

// Filter last instance of update_page_media perPage
function updatePageMediaMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasUpdatePageMedia = newQueue.some(isUpdatePageMediaCommand)
	if (!hasUpdatePageMedia) return newQueue

	const uniqueUpdatePageMediaCommands = newQueue
		.filter(isUpdatePageMediaCommand)
		.reduce<Record<string, UpdatePageMediaCommand>>((group, command) => {
			if (!isUpdatePageMediaCommand(command)) return group

			group[command.data.page_id] = command
			return group
		}, {})

	newQueue = newQueue.filter((command) => !isUpdatePageMediaCommand(command))

	return [...newQueue, ...Object.values(uniqueUpdatePageMediaCommands)]
}

// If first page is detected on update_pages_indexes send update media command
function updatePageMediaIfFirstPageReindexedMutator(commandList: Command[]) {
	const updatePagesIndexesCommand = commandList.find(isUpdatePagesIndexesCommand)
	if (!updatePagesIndexesCommand) return commandList

	const FIRST_PAGE = 1
	const pageId = Object.keys(updatePagesIndexesCommand.data.pages).find(
		(key) => updatePagesIndexesCommand.data.pages[key] === FIRST_PAGE
	)
	if (!pageId) return commandList

	const userToken = localStorage.getItem('userToken')
	const updatePageMediaCommand: UpdatePageMediaCommand = {
		id: updatePagesIndexesCommand.id,
		action: 'update_page_media',
		endpoint: 'send_changes',
		resource: 'flyer',
		data: {
			project_id: updatePagesIndexesCommand.data.project_id,
			page_id: pageId,
		},
		token: userToken || '',
	}

	return [...commandList, updatePageMediaCommand]
}

// Group all update_page_media commands into a single command
function groupUpdatePageMediaCommandsMutator(commandList: Command[]) {
	let newQueue = commandList

	const hasUpdatePageMedia = newQueue.some(isUpdatePageMediaCommand)
	if (!hasUpdatePageMedia) return newQueue

	const updatePageMediaCommands = newQueue.filter(isUpdatePageMediaCommand)
	const firstUpdatePageMedia = updatePageMediaCommands.find(Boolean)
	if (!firstUpdatePageMedia) return newQueue
	const userToken = localStorage.getItem('userToken')

	const groupedUpdatePageMediaCommands: MultiEventsCommand = {
		id: firstUpdatePageMedia.id,
		action: 'multi_events',
		endpoint: 'send_changes',
		commands: updatePageMediaCommands,
		token: userToken || '',
	}

	newQueue = newQueue.filter((command) => !isUpdatePageMediaCommand(command))

	return [...newQueue, groupedUpdatePageMediaCommands]
}

// If multi_events has too many commands split in chunks
function multiEventsChunkerMutator(commandList: Command[]) {
	const newQueue = [...commandList]
	const chunkSize = 50

	const hasMultiEventsCommand = newQueue.some(isMultiEventsCommand)
	if (!hasMultiEventsCommand) return newQueue

	const multiEventsCommands = newQueue.filter(
		(command) =>
			isMultiEventsCommand(command) && command.commands.length > chunkSize
	)
	if (!multiEventsCommands) return newQueue

	const newMultiCommandsGroup: MultiEventsCommand[][] = []
	multiEventsCommands.forEach((multiEventsCommand) => {
		if (!isMultiEventsCommand(multiEventsCommand)) return

		const chunkedCommandList = multiEventsCommand.commands.reduce<
			WebSocketPayload[][]
		>((group, command, index) => {
			const chunkIndex = Math.floor(index / chunkSize)
			if (!group[chunkIndex]) {
				group[chunkIndex] = []
			}
			group[chunkIndex].push(command)
			return group
		}, [])

		const multiEvents = chunkedCommandList.map((chunkedCommands) => {
			const multiEvent: MultiEventsCommand = {
				...multiEventsCommand,
				commands: chunkedCommands,
			}
			return multiEvent
		})

		newMultiCommandsGroup.push(multiEvents)
	})

	multiEventsCommands.forEach((multiEventCommand, idx) => {
		const commandIndex = newQueue.indexOf(multiEventCommand)

		newQueue.splice(commandIndex, 1, ...newMultiCommandsGroup[idx])
	})

	return newQueue
}

export function queueMutator(commandList: Command[]) {
	let newQueue = commandList

	newQueue = updatePagesIndexesMutator(newQueue)
	newQueue = updatePagesIndexesChunkerMutator(newQueue)
	newQueue = addProductsMutator(newQueue)
	newQueue = updatePageMutator(newQueue)
	newQueue = addSpreadPageMutator(newQueue)
	newQueue = sortById(newQueue)
	newQueue = mergeAddPageMutator(newQueue)
	newQueue = updatePageMediaMutator(newQueue)
	newQueue = updatePageMediaIfFirstPageReindexedMutator(newQueue)
	newQueue = groupUpdatePageMediaCommandsMutator(newQueue)
	newQueue = multiEventsChunkerMutator(newQueue)

	return newQueue
}
