import { reactive, ref, watch } from 'vue'
import { debounce, makeInstanceFactory } from '@zoomcatalog/shared'
import type { BasicPage, Page } from '@/store/interfaces'
import {
	Command,
	MultiEventsCommand,
	UpdatePageCommand,
	UpdatePagesIndexesCommand,
} from '@/store/plugins/autoSave/types'
import { useCommit, useSelector } from '@/store/rootStore'
import { storageInstances } from '@/utils/storageIntances'
import { usePageSettingsStore } from './StoreUtils/usePageSettingsStore'
import { useProjectStore } from './StoreUtils/useProjectStore'
import { useNotification } from './useNotification'
import { getNormalizedPage } from './usePagination'

const [
	useMakePaginationFixerInstance,
	usePaginationFixerInstance,
	usePaginationFixerHandlers,
] = makeInstanceFactory('pagination-fixer', () => {
	const { enqueueNotification } = useNotification()
	const commit = useCommit()
	const isSending = useSelector((state) => state.autoSave.isSending)
	const { projectData } = useProjectStore()
	const pageSettingsStore = usePageSettingsStore()
	const invalidSpreadPages = ref<BasicPage[]>()

	/**
	 * Handlers
	 */
	function hasRepeatedBasicPages(pages: BasicPage[]) {
		const uniquePageIds = new Set(pages.map((page) => page.index))

		return uniquePageIds.size < pages.length
	}

	function hasInvalidPageNumbersOrder(pages: BasicPage[]) {
		return !pages.every((page, index) => {
			const expectedPageNumber = index + 1
			return page.index === expectedPageNumber
		})
	}

	function filterInvalidSpreadPages(basicPages: BasicPage[]) {
		const sortedPages = sortPages(basicPages)

		return sortedPages.reduce<typeof basicPages[number][]>(
			(group, page, index) => {
				const prevPage = basicPages?.[index - 1]
				const currentPage = page
				const nextPage = basicPages?.[index + 1]

				if (!currentPage.spread_page) return group
				if (currentPage.spread_page === 'left' && nextPage?.spread_page === 'right')
					return group
				if (prevPage?.spread_page === 'left' && currentPage.spread_page === 'right')
					return group

				return [...group, currentPage]
			},
			[]
		)
	}

	function sortPages<T extends BasicPage[] | Page[]>(pages: T) {
		return [...pages].sort((a, b) => a.index - b.index)
	}

	function verifyBasicPages(context: {
		projectId: string
		basicPages: BasicPage[]
	}) {
		const commandId = ref(1)
		const commandsToFixProject = ref<Command[]>([])
		const sortedBasicPages = sortPages(context.basicPages)

		if (
			!hasRepeatedBasicPages(sortedBasicPages) &&
			!hasInvalidPageNumbersOrder(sortedBasicPages)
		) {
			invalidSpreadPages.value = filterInvalidSpreadPages(sortedBasicPages)
			return
		}

		const updatePagesIndexes: UpdatePagesIndexesCommand = {
			action: 'update_pages_indexes',
			endpoint: 'send_changes',
			resource: 'flyer',
			token: storageInstances.userToken.get(),
			id: commandId.value,
			data: {
				project_id: context.projectId,
				pages: Object.values(sortedBasicPages).reduce(
					(obj, page, index) => ({ ...obj, [page.page_id]: index + 1 }),
					{}
				),
			},
		}
		commandId.value += 1
		commandsToFixProject.value.push(updatePagesIndexes)

		sendCommandsAndReload(commandsToFixProject.value)
	}

	function verifySpreadPages(context: { projectId: string; pages: Page[] }) {
		const commandId = ref(1)
		const commandsToFixProject = ref<Command[]>([])

		if (!invalidSpreadPages.value?.length) return

		invalidSpreadPages.value.forEach((invalidSpreadPage) => {
			const page = context.pages.find(
				(page) => page.page_id === invalidSpreadPage.page_id
			)
			if (!page) return

			const updatePageCommand: UpdatePageCommand = {
				action: 'update_page',
				endpoint: 'send_changes',
				resource: 'flyer',
				id: commandId.value,
				token: storageInstances.userToken.get(),
				data: {
					cover_image: page.cover_image,
					title: page.title,
					index: page.index,
					layout_id: page.layout_id,
					page_id: page.page_id,
					project_id: projectData.value.project_id,
					settings: {
						...page.settings,
						feature: { ...page.settings?.feature, spreadPage: null },
					},
				},
			}
			commandsToFixProject.value.push(updatePageCommand)
		})

		if (!commandsToFixProject.value.length) return

		const multiCommand: MultiEventsCommand = {
			action: 'multi_events',
			endpoint: 'send_changes',
			id: 1,
			token: storageInstances.userToken.get(),
			commands: commandsToFixProject.value,
		}

		sendCommandsAndReload([multiCommand])
	}

	function sendCommandsAndReload(commands: Command[]) {
		enqueueNotification({
			message:
				'Your previous session was not saved successfully, we are trying to restore it',
			color: 'danger',
			closable: false,
			delay: 0,
		})

		commit({
			type: 'autoSave/sendMultiCommands',
			payload: commands,
		})

		watch(
			isSending,
			(newIsSending) => {
				if (!newIsSending && !projectData.value.is_locked) {
					debouncePageReload()
				}
			},
			{ immediate: true }
		)
	}

	const debouncePageReload = debounce(() => {
		window.location.reload()
	}, 4000)

	function addSpreadPageDefaultSetting(pages: Page[]) {
		pages.forEach((page) => {
			if (page.settings?.feature && 'spreadPage' in page.settings.feature) return

			const normalizedPage = getNormalizedPage(page)

			pageSettingsStore.setPageSettings(
				{
					...normalizedPage.settings,
					feature: {
						...normalizedPage.settings?.feature,
						spreadPage: null,
					},
				},
				normalizedPage
			)
		})
	}

	return [
		reactive({}),
		{
			verifyBasicPages,
			verifySpreadPages,
			addSpreadPageDefaultSetting,
		},
	]
})

export {
	useMakePaginationFixerInstance,
	usePaginationFixerInstance,
	usePaginationFixerHandlers,
}
